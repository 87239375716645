import { EventBus } from '~/plugins/event-bus';
import {disableLoader, enableLoader} from "@/helpers/loader";

export default {
  async init({ dispatch, state, rootGetters  }) {

    if (!state.sites?.length && !rootGetters['auth/isWorker']) {
      dispatch('fetch')
    }
  },

  async fetch({ commit }) {
    try {
      const sites = await this.$axios.get(`/api/me/sites`)
      commit('setSites', sites.data.data)
      commit('toggleData')
    } catch (err) {
      console.log(err)
    }
  },

  async fetchSite({ commit, state }, payload) {
    try {
      let siteId = null
      let params = {}
      if (typeof payload === 'object') {
        siteId = payload.siteId
        params = payload.params || params
      } else {
        siteId = payload
      }
      const site = await this.$axios.get(`/api/me/sites/${siteId}`, {params});
      commit('setMeta', site.data.meta);
      commit('setSite', site.data.data);
    } catch (err) {
      commit('setMeta', err);
      console.log(err);
    }
  },


  async fetchSiteSubcontractors({ commit, state }, siteId) {
    commit('setSubcontractors', null);
    try {
      const subcontractors = await this.$axios.get(`/api/me/sites/${siteId}/subcontractors`);
      commit('setSubcontractors', subcontractors.data.data);
    } catch (err) {
      console.log(err);
    }
  },

  async fetchSiteWorkers({ commit }, payload) {
    if (payload.refresh) {
      const { page, per_page } = payload.query
      payload.query.per_page = page * per_page
      payload.query.page = 1
    }

    const dataSlotName = payload.dataSlotName ?? 'default'

    try {
      const response = await this.$axios.get(
        `/api/me/sites/${payload.site_id}/workers`,
        { params: payload.query }
      );

      const { data, meta } = response['data'];

      if (payload.reset) {
        commit('setSiteWorkers', {workers: data, dataSlotName});
      } else {
        commit('addSiteWorkers', {workers: data, dataSlotName});
      }

      if (!payload.refresh) {
        // Do not set meta when refreshing to preserve current page and page size.
        commit('setSiteWorkersMeta', {meta, dataSlotName});
      }

      return response['data'];

    } catch (err) {
      commit('setSiteWorkersMeta', {err, dataSlotName});
    }
  },

  async fetchOnSiteWorkersDiff({ commit }, payload) {

    const dataSlotName = payload.dataSlotName ?? 'default'

    try {
      const response = await this.$axios.post(
        `/api/me/sites/${payload.site_id}/workers`, {
          ...payload.filters,
          onsite: payload.site_id,
          onsite_since: payload.onsite_since,
          check_offsite: payload.workers_onsite.map( w => w.id)
      })

      if (payload.on_diff) {
        payload.on_diff(response.data)
      }

      if (response.data.new_onsite.data.length || response.data.left_site.length) {

        commit('updateOnSiteWorkersFromDiff', {
          dataSlotName: payload.dataSlotName,
          response: response.data
        })

      }

      return response

    } catch (err) {
    }
  },

  async update({ commit, dispatch }, payload) {
    enableLoader()
    commit('setError', '');
    try {
      let response;
      if (
        'undefined' !== typeof payload.formData.id &&
        null !== payload.formData.id
      ) {
        response = await this.$axios.put(
          '/api/me/sites/' + payload.formData.id,
          payload.formData
        );
      } else {
        response = await this.$axios.post('/api/me/sites', payload.formData);
      }
      EventBus.$emit('createSnackbar', {
        message: `Site ${payload.formData.name} ${payload.action} successfully`,
      });
      await this.$router.push(payload.redirectUrl);
    } catch (err) {
      commit('setError', err);
    }
    disableLoader()
  },

  async updateAsSubcompany({ commit, dispatch }, payload) {
    enableLoader()
    commit('setError', '');
    try {
      let response;
      if (
        'undefined' !== typeof payload.formData.id &&
        null !== payload.formData.id
      ) {
        response = await this.$axios.put(
          '/api/me/sites/' + payload.formData.id + '/subcompanies',
          payload.formData
        );
      } else {
        response = await this.$axios.post('/api/me/sites', payload.formData);
      }
      EventBus.$emit('createSnackbar', {
        message: `Site ${payload.formData.name} ${payload.action} successfully`,
      });
      await this.$router.push(payload.redirectUrl);
    } catch (err) {
      commit('setError', err);
    }
    disableLoader()
  },


  async updateSiteManagers({ commit, dispatch }, payload) {
    enableLoader()
    commit('setError', '');

    try {
      const response = await this.$axios.post(
        `/api/me/sites/${payload.id}/managers`, payload.formData);

      commit('setSiteManagers', response.data.data)

      EventBus.$emit('createSnackbar', {
        message: `Site managers updated successfully`,
      });
    } catch (err) {
      EventBus.$emit('createSnackbar', {
        message: `Failed to update Site managers`,
      });
      commit('setError', err);
    }
    disableLoader()
  },

  async updateSiteSubcontractors({ commit, dispatch }, payload) {
    enableLoader()
    commit('setError', '');

    try {
      const response = await this.$axios.post(
        `/api/me/sites/${payload.id}/subcontractors`, payload.data);

      commit('setSubcontractors', response.data.data)

      EventBus.$emit('createSnackbar', {
        message: `Site subcontractors updated successfully`,
      });
    } catch (err) {
      EventBus.$emit('createSnackbar', {
        message: `Failed to update Site managers`,
      });
      commit('setError', err);
    }
    disableLoader()
  },
  async firePrint({ commit, dispatch }, id) {
    try {
      const response = await this.$axios.get(`/api/me/sites/${id}/fire`);
      commit('setFirePrintUrl', response.data.data.url);
    } catch (err) {
      commit('setError', err);
    }
  },

  async checkins({ commit, state }, payload) {
    try {
      const response = await this.$axios.get(
        `/api/me/sites/${payload.siteId}/checkins`, {
          params: payload.query,
        })
      
      const { data: checkins, meta } = response.data

      commit('setCheckins', {
        checkins: checkins,
        replace: payload.reset
      })
      commit('setCheckinsMeta', meta)

    } catch (err) {
      commit('setCheckinsMeta', err)
      console.log(err)
    }
  },

  async gateLog({ commit, state }, payload) {
    try {
      const gateLog = await this.$axios.get(
        `/api/gate/${payload.gateId}/log`,
        {
          params: payload.query,
        }
      );
      return gateLog.data
    } catch (err) {
      console.log(err);
    }
  },

  async editGate({ commit, state }, payload) {
    enableLoader()
    try {
      const res = await this.$axios.put(
        `/api/gate/${payload.id}`,
        {
          gate_id: payload.gateId,
        }
      );
    } catch (err) {
      console.log(err);
      EventBus.$emit('createSnackbar', {
        message: `Failed to update Gate ID`,
      });
      commit('setError', err);
    }
    disableLoader()
  },

  async deleteGate({ commit, state }, payload) {
    enableLoader()
    try {
      const res = await this.$axios.delete(
        `/api/gate/${payload.id}`,
        {}
      );
    } catch (err) {
      console.log(err);
      EventBus.$emit('createSnackbar', {
        message: `Failed to delete gate`,
      });
      commit('setError', err);
    }
    disableLoader()
  },

  async addGate({ commit, state }, payload) {
    enableLoader()
    try {
      const res = await this.$axios.post(
        `/api/gate`,
        {
          gate_id: payload.gateId,
          site_id: parseInt(payload.siteId, 10),
        }
      );
    } catch (err) {
      console.log(err);
      EventBus.$emit('createSnackbar', {
        message: `Failed to add gate to the site`,
      });
      commit('setError', err);
    }
    disableLoader()
  },

  async fetchSiteSubcompanies({ commit }, id) {
    commit('setError', '');

    try {
      const response = await this.$axios.get(`api/me/sites/${id}/subcompanies`)
      commit('setSubcontractors', response.data.data)
    } catch (err) {
      commit('setError', err);
    }
  },

  async fetchSubcompanyRequests({commit}) {
    commit('setError', '')
    try {
      const response = await this.$axios.get(`api/me/sites/accessRequests`)
      commit('setSubcompanyRequests', response.data.data)
    } catch (err) {
      commit('setError', err)
    }
  },

  async fetchUpdatedSiteSubcompanies({ commit }, data) {
    commit('setError', '');
    try {
      const response = await this.$axios.get(`api/me/sites/${data.id}/subcompanies`, {
        params: {
          sort: data.sort
        }
      })
      commit('setSubcontractors', response.data.data)
    } catch (err) {
      commit('setError', err);
    }
  },

  async fetchSiteGates({ commit }, { site_id }) {
    commit('setError', '');

    try {
      const response = await this.$axios.get(`api/me/sites/${site_id}/gates/status`)
      commit('setGates', response.data.data)
    } catch (err) {
      commit('setError', err);
    }
  },

  async switchGate({ commit }, { gateId, position }) {
    try {
      await this.$axios.post(`/api/gate/${gateId}/${position}`, {});
    } catch (err) {
      commit('setError', err)
    }
  },

  async fetchSiteSubcompany({ commit }, payload) {
    commit('setSubcontractor', null)
    commit('setError', '');

    try {
      const response = await this.$axios.get(`api/me/sites/${payload.id}/subcompanies/${payload.subcompany}`)
      commit('setSubcontractor', response.data.data)
    } catch (err) {
      commit('setError', err);
    }
  },

  async assignSubcompanyManager({commit}, payload) {
    enableLoader()
    commit('setError', '');
    try {
      const response = await this.$axios.put(`/api/me/sites/${payload.id}/subcompanies/${payload.subcompany}/manager`,
        { manager_id: payload.manager?.id ?? (payload.manager ?? null)  })
      commit('setSubcontractor', response.data.data)
      EventBus.$emit('createSnackbar', {
        message: `Manager assigned successfully`,
      });
    } catch (err) {
      commit('setError', err)
    }

    disableLoader()
  },

  async resolveAccessRequest({commit}, payload) {
    enableLoader()
    commit('setError', '');
    try {
      await this.$axios.put(`/api/me/sites/${payload.id}/subcompanies/${payload.subcompany}/access`,
        { accept: payload.accept ? 1 : 0 })
      EventBus.$emit('createSnackbar', {
        message: `Request ${payload.accept ? 'accepted' : 'rejected'}  successfully`,
      });
    } catch (err) {
      EventBus.$emit('createSnackbar', {
        message: err.response.data.message,
      });
    }

    disableLoader()
  },

  async terminateSubcompany({commit}, payload) {
    enableLoader()
    commit('setError', '');

    try {
      await this.$axios.delete(`/api/me/sites/${payload.id}/subcompanies/${payload.subcompany}`, {
        data: {
          notify: payload?.notify ?? true
        }
      })

      EventBus.$emit('createSnackbar', {
        message: `Site Access Ended succesfully`,
      });
    } catch (err) {
      commit('setError', err)
      EventBus.$emit('createSnackbar', {
        message: `Failed to terminate cooperation.`,
      });
    }

    disableLoader()
  },

  async fetchCheckInExcelUrl({ commit, state }, payload) {
    try {
      const response = await this.$axios.get(`/api/me/sites/${payload.id}/checkins/excel`,{ params: { ...payload.filters } });
      commit('setCheckInExcelUrl', response.data.data.url);
    } catch (err) {
      commit('setError', err);
    }
  },

  async fetchCheckInsExcelDownloadUrl({ commit, state }, payload) {
    try {
      const response = await this.$axios.get(`/api/me/sites/${payload.site_ids[0]}/sitecheckins/excel`,{ params: payload });
      commit('setCheckInExcelUrl', response.data.data.url);
    } catch (err) {
      commit('setError', err);
    }
  },

  async fetchWorkerDocAudit({ commit }, payload) {

    if (payload.refresh) {
      const { page, per_page } = payload.query
      payload.query.per_page = page * per_page
      payload.query.page = 1
    }

    try {
      const response = await this.$axios.get(
        `/api/me/sites/${payload.site_id}/docsAudit`,
        { params: payload.query }
      );

      const { data, meta } = response['data'];

      if (payload.reset) {
        commit('setWorkerDocAuditRecs', data);
      } else {
        commit('addWorkerDocAuditRecs', data);
      }

      if (!payload.refresh) {
        // Do not set meta when refreshing to preserve current page and page size.
        commit('setWorkerDocAuditRecsMeta', meta);
      }

      return response['data'];

    } catch (err) {
      commit('setWorkerDocAuditRecsMeta', err);
    }
  },

  async fetchSiteCheckins({ commit, state }, payload) {
    try {
      const response = await this.$axios.get(
        `/api/me/sites/${payload.site_id}/sitecheckins`, {
          params: payload.query,
        })
      
      const { data: checkins, meta } = response.data

      commit('setSiteCheckins', {
        checkins: checkins,
        replace: payload.reset
      })
      commit('setSiteCheckinsMeta', meta)

    } catch (err) {
      commit('setSiteCheckinsMeta', err)
      console.log(err)
    }
  },

};
